import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import bannerImg from "../../Assets/Images/banner.png";

const Banner = () => {
  return (
    <div className="banner_page">
      <Row>
        <Col xl={6} className="banner_left_content">
          <div
            className="banner_text"
            data-aos="slide-right"
            data-aos-duration="1000"
          >
            <h2 className="headig_text">
              <span className="highlighted_text"> Web 4.0, Aggregated:</span>
              <br />
              Starfire-Powered AI Blockchain for Infinite Scalability
            </h2>
            <p className="common_p">
              Leading the charge in creating intelligent, scalable, and secure
              blockchain solutions.
            </p>
            <div className="banner_buttons d-flex gap-4">
              <button className="btn common_btn">Learn More</button>
              <button className="btn common_btn2">Get Started</button>
            </div>
          </div>
        </Col>
        <Col xl={6} className="p-0">
          <div
            className="banner_img"
            data-aos="slide-left"
            data-aos-duration="1000"
          >
            <img className="img-fluid" src={bannerImg} alt="banner" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Banner;
