import React from "react";
import left from "../../Assets/Images/left.svg";
import right from "../../Assets/Images/right.svg";
import mark from "../../Assets/Images/mark.svg";
import { Container } from "react-bootstrap";

function Overview() {
  return (
    <>
      <div className="bg-color  padding-bottom border">
        <Container>
          <div className="d-flex mt-5 gap-5  ">
            <div>Block Details</div>
            <div>
              <img src={left} alt="left" className="button-overview1 me-2" />
              <img src={right} alt="right" className="button-overview1" />
            </div>
          </div>
          <div className="button-overview">Overview</div>

          <div className="mt-3 p-3 border rounded bg-white shadow-sm font-size text-gray">
            <div className="d-flex padding-left1 padding-right1 align-items-center  mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Block Height</p>
              </div>
              <div className="col-9">10371440</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Status</p>
              </div>
              <div className="col-9">Finalized</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Proposer</p>
              </div>
              <div className="col-9 text-pink ">
                0x7F73862F0672C066C3f6B4330a736479f0345cD7
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Validator Detail</p>
              </div>
              <div className="col-9 ">kraken</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Timestamp</p>
              </div>
              <div className="col-9 ">
                24 minutes ago(2024-06-20 06:19:40 +UTC)
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Transactions</p>
              </div>
              <div className="col-9 ">
                <span className="text-pink">0 transactions</span>  in block
              </div>
            </div>
            <hr />
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Size</p>
              </div>
              <div className="col-9 ">10,916bytes</div>
            </div>
            <hr />
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Gas Used / Limit</p>
              </div>
              <div className="col-9 ">0(0.00%)/20,000,000 ThunderHawk</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Fee</p>
              </div>
              <div className="col-9 ">0.000000000000000000 ThunderHawk</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Base Fee Per Gas</p>
              </div>
              <div className="col-9 ">0.000000000000000000 ThunderHawk</div>
            </div>
          </div>

          <div className="mt-3 p-3 border rounded bg-white shadow-sm font-size text-gray">
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Hash</p>
              </div>
              <div className="col-9 ">
                0x1cb008c6841786fd1bfa975a49caea00b0bfdcd7fe9abc5c6fcbb323ee41b038
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Parent Hash</p>
              </div>
              <div className="col-9 ">
                0x63d71c24eec659608b935e1ecfa8344e7888292009eb6726eaf36c41e52f9cbf
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">StateRoot</p>
              </div>
              <div className="col-9 ">
                0x8e938122d561461fb3db83eddddd663a5fc4aec91eaccb9a9dd59cdb709e753e
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Nonce</p>
              </div>
              <div className="col-9 ">0x0000000000000000</div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Overview;
