import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function List() {
  const navigate = useNavigate();
  const LatestBlock = [
    {
      Smartcontract: "Thunders",
      channelName: "Centeralauthority",
      path: "-",
      TransactionCount: "0",
      chainCode: "_lifecycle",
      Version: "2.0",
    },
    {
      Smartcontract: "Thunders",
      channelName: "Centeralauthority",
      path: "-",
      TransactionCount: "0",
      chainCode: "_lifecycle",
      Version: "2.0",
    },
    {
      Smartcontract: "Thunders",
      channelName: "Centeralauthority",
      path: "-",
      TransactionCount: "0",
      chainCode: "_lifecycle",
      Version: "2.0",
    },
    {
      Smartcontract: "Thunders",
      channelName: "Centeralauthority",
      path: "-",
      TransactionCount: "0",
      chainCode: "_lifecycle",
      Version: "2.0",
    },
    {
      Smartcontract: "Thunders",
      channelName: "Centeralauthority",
      path: "-",
      TransactionCount: "0",
      chainCode: "_lifecycle",
      Version: "2.0",
    },
  ];

  return (
    <>
      <Container>
        <div className="border border_grey rounded-3 bg-white shadow-sm ml-4 p-3 mt-5">
          {/* <div className="text-start pb-4 fw-semibold">LATEST BLOCKS</div> */}
          <table className="table table-striped border border_grey text-center">
            <thead>
              <tr>
                <th scope="col">Smartcontract Name</th>
                <th scope="col">Channel Name</th>
                <th scope="col">Path</th>
                <th scope="col">Transaction Count</th>
                <th scope="col">Version</th>
              </tr>
            </thead>
            <tbody>
              {LatestBlock.map((e, index) => (
                <tr key={index}>
                  <td>
                    <p
                      className="font-size-banner-entries mb-1"
                      onClick={() => navigate("/smartcontractdetails")}
                    >
                      {e.Smartcontract}
                    </p>
                  </td>
                  <td>
                    <p className="font-size-banner-entries mb-1">
                      {e.channelName}
                    </p>
                  </td>
                  <td>
                    <p className="font-size-banner-entries mb-1">{e.path}</p>
                  </td>
                  <td>
                    <p className="font-size-banner-entries mb-1">
                      {e.TransactionCount}
                    </p>
                  </td>
                  <td>
                    <p className="font-size-banner-entries mb-1">{e.Version}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button className="w-100 bg-light text-dark font-size-banner-entries">
            View All Latest Transactions
          </Button>
        </div>
      </Container>
    </>
  );
}

export default List;
