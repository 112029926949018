import React from "react";
import { Button, Container } from "react-bootstrap";
import block from "../../Assets/Images/block.png";
import { Link } from "react-router-dom";

const Expbanner = () => {
  const LatestBlock = [
    {
      height: "0.00001019",
      time: " seconds ago",
      transactions: "3 Txns",
      blockInfo: "56/20M",
      fee: "0.000236 ",
    },
    {
      height: "0.00001019",
      time: " seconds ago",
      transactions: "3 Txns",
      blockInfo: "56/20M",
      fee: "0.000236 ",
    },
    {
      height: "0.00001019",
      time: " seconds ago",
      transactions: "3 Txns",
      blockInfo: "56/20M",
      fee: "0.000236 ",
    },
    {
      height: "0.00001019",
      time: " seconds ago",
      transactions: "3 Txns",
      blockInfo: "56/20M",
      fee: "0.000236 ",
    },
    {
      height: "0.00001019",
      time: " seconds ago",
      transactions: "3 Txns",
      blockInfo: "56/20M",
      fee: "0.000236 ",
    },
    {
      height: "0.00001019",
      time: " seconds ago",
      transactions: "3 Txns",
      blockInfo: "56/20M",
      fee: "0.000236 ",
    },
    {
      height: "0.00001019",
      time: " seconds ago",
      transactions: "3 Txns",
      blockInfo: "56/20M",
      fee: "0.000236 ",
    },
    {
      height: "0.00001019",
      time: " seconds ago",
      transactions: "3 Txns",
      blockInfo: "56/20M",
      fee: "0.000236 ",
    },
  ];

  const LatestTnx = [
    {
      txHash: "0x75a667...89e41214",
      from: "0x75a667...89e41214",
      to: "0x02084E...03cAe607",
      value: "0.000236",
      timeAgo: " seconds ago",
    },
    {
      txHash: "0x75a667...89e41214",
      from: "0x75a667...89e41214",
      to: "0x02084E...03cAe607",
      value: "0.000236",
      timeAgo: " seconds ago",
    },
    {
      txHash: "0x75a667...89e41214",
      from: "0x75a667...89e41214",
      to: "0x02084E...03cAe607",
      value: "0.000236",
      timeAgo: " seconds ago",
    },
    {
      txHash: "0x75a667...89e41214",
      from: "0x75a667...89e41214",
      to: "0x02084E...03cAe607",
      value: "0.000236",
      timeAgo: " seconds ago",
    },
    {
      txHash: "0x75a667...89e41214",
      from: "0x75a667...89e41214",
      to: "0x02084E...03cAe607",
      value: "0.000236",
      timeAgo: " seconds ago",
    },
    {
      txHash: "0x75a667...89e41214",
      from: "0x75a667...89e41214",
      to: "0x02084E...03cAe607",
      value: "0.000236",
      timeAgo: " seconds ago",
    },
    {
      txHash: "0x75a667...89e41214",
      from: "0x75a667...89e41214",
      to: "0x02084E...03cAe607",
      value: "0.000236",
      timeAgo: " seconds ago",
    },
    {
      txHash: "0x75a667...89e41214",
      from: "0x75a667...89e41214",
      to: "0x02084E...03cAe607",
      value: "0.000236",
      timeAgo: " seconds ago",
    },
  ];
  return (
    <>
      <div className="bg-color">
        <Container>
          <div className="col-12 d-flex justify-content-center gap-4 p-3 py-5">
            <div className="col-6 border border_grey rounded-3 bg-white shadow-sm ml-4 p-3">
              <div className="text-start pb-4 fw-semibold ">LATEST BLOCKS</div>
              <table className="table table-striped border border_grey">
                <thead>
                  <tr>
                    <th scope="col">Height</th>
                    <th scope="col">Txn</th>
                    <th scope="col">Fee</th>
                  </tr>
                </thead>
                <tbody>
                  {LatestBlock.map((e, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={block}
                            alt=""
                            className="img-fluid me-3"
                            style={{ width: "50px", height: "50px" }}
                          />
                          <div>
                            <p className="font-size-banner-entries pink mb-1">
                              {e.height}
                            </p>
                            <p className="text-muted font-size-banner-entries mb-0 text-muted">
                              {e.time}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="font-size-banner-entries mb-1">
                          <span className="highlighted_text pink">{e.txn}</span>{" "}
                          tax in a block
                        </p>
                        <p className="text-muted font-size-banner-entries mb-0">
                          {e.blockInfo}
                        </p>
                      </td>
                      <td>
                        <p className="border border-light bg-gray-700 font-size-banner-entries bg-white p-1 border_grey my-2 rounded text-center">
                          {e.fee} Thunder
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button className="w-100 bg-light text-dark font-size-banner-entries">
                VIEW ALL BLOCKS
              </Button>
            </div>

            <div className="col-6 border border_grey rounded-3 bg-white shadow-sm ml-4  p-3">
              <div className="text-start pb-4 fw-semibold">
                LATEST TRANSACTION
              </div>
              <table className="table table-striped border border_grey">
                <thead>
                  <tr>
                    <th scope="col">TX HASH</th>
                    <th scope="col">FROM/TO</th>
                    <th scope="col">VALUE</th>
                  </tr>
                </thead>
                <tbody>
                  {LatestTnx.map((e, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={block}
                            alt=""
                            className="img-fluid me-3"
                            style={{ width: "50px", height: "50px" }}
                          />
                          <div>
                            <Link
                              to={`/transactions/${e.txHash}`}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              <p className="text-pink mb-1 font-size-banner-entries">
                                {e.txHash}
                              </p>
                            </Link>
                            <p className="text-muted mb-0 font-size-banner-entries text-muted">
                              {e.timeAgo}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p className="text-pink mb-1 font-size-banner-entries">
                          From: {e.from}
                        </p>
                        <p className="text-muted mb-0 font-size-banner-entries text-muted">
                          To: {e.to}
                        </p>
                      </td>
                      <td>
                        <p className="border border-light bg-gray-700 font-size-banner-entries bg-white p-1 border_grey my-2 rounded text-center">
                          {e.value} Thunder
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button className="w-100 bg-light text-dark">
                View All Latest Transactions
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Expbanner;
