import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Table() {
  const navigate = useNavigate();
  const LatestBlock = [
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
    {
      creator: "ORG1MSP",
      channelName: "Centeralauthority",
      txId: "369UIN203......",
      type: "NOT DEFINED",
      chainCode: "_lifecycle",
      timestamp: "2024-09-03T12:55:36.958Z",
    },
  ];

  return (
    <>
      <Container>
        <div className="border border_grey rounded-3 bg-white shadow-sm ml-4 p-3 mt-5">
          <div className="text-start pb-4 fw-semibold">LATEST BLOCKS</div>
          <table className="table table-striped border border_grey text-center">
            <thead>
              <tr>
                <th scope="col">Creator</th>
                <th scope="col">Channel Name</th>
                <th scope="col">Transaction ID</th>
                <th scope="col">Type</th>
                <th scope="col">Chain Code</th>
                <th scope="col">Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {LatestBlock.map((e, index) => (
                <tr key={index}>
                  <td>
                    <p className="font-size-banner-entries  mb-1">
                      {e.creator}
                    </p>
                  </td>
                  <td>
                    <p className="font-size-banner-entries mb-1">
                      {e.channelName}
                    </p>
                  </td>
                  <td>
                    <p
                      className="font-size-banner-entries pink mb-1"
                      onClick={() => navigate("/transactionDetail")}
                    >
                      {e.txId}
                    </p>
                  </td>
                  <td>
                    <p className="font-size-banner-entries mb-1">{e.type}</p>
                  </td>
                  <td>
                    <p className="font-size-banner-entries mb-1">
                      {e.chainCode}
                    </p>
                  </td>
                  <td>
                    <p className="font-size-banner-entries mb-1">
                      {e.timestamp}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button className="w-100 bg-light text-dark font-size-banner-entries">
            View All Latest Transactions
          </Button>
        </div>
      </Container>
    </>
  );
}

export default Table;
