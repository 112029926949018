import React from "react";
import "./landingPage.css";
import Header from "./header";
import Banner from "./banner";
import Counts from "./counts";
import Innovation from "./innovation";
import EasyToStart from "./easyToStart";
import Technologies from "./technologies";
import Solutions from "./solutions";
import Blog from "./blog";
import Community from "./community";
import Contact from "./contact";
import Footer from "./footer";

const index = () => {
  return (
    <React.Fragment>
      <Header />
      <Banner />
      <Counts />
      <Innovation />
      <EasyToStart />
      <Solutions />
      <Community />
      <Technologies />
      <Blog />
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default index;
