import React from "react";
import { Col, Row } from "react-bootstrap";
import logo from "../../Assets/Images/logo.png";
import { RiTwitterXFill } from "react-icons/ri";
import { PiTelegramLogo, PiRedditLogo, PiDiscordLogoBold } from "react-icons/pi";

import { TbNews } from "react-icons/tb";
import { GrBlog } from "react-icons/gr"


const Footer = () => {
  const data = [
    {
      heading: "Developers",
      content: [
        "Build",
        "Builder's home",
        "Tutorials",
        "Documentation",
        "Learn by coding",
        "Set up local environment",
        "Grants",
        "Github",
        "Discord",
      ],
    },
    {
      heading: "Community",
      content: [
        {name:"Twitter", icon: <RiTwitterXFill /> },
        {name:"Discord", icon: <PiDiscordLogoBold /> },
        {name:"Telegram", icon: <PiTelegramLogo /> },
        {name:"Reddit", icon: <PiRedditLogo /> },
        {name:"News Letter", icon: <TbNews /> },
        {name:"Blog", icon: <GrBlog /> },
      ],
    },
    {
      heading: "About us",
      content: [
        "Code of Conduct",
        "Tabs",
        "Privacy Policy",
        "Tems of Use",
        "Cookie Policy",
        "Press Conduct",
      ],
    },
    {
      heading: "Others",
      content: [
        "Launch App",
        "Strategic Vault",
        "Stats",
        "Audits",
        "Careers",
        "Branding",
        "Partnership",
        "Security and Compliance",
      ],
    },
  ];
  return (
    <>
      <div className="footer_page py-2">
        <div className="common_container mt-4 mb-4" >
          <Row>
            <Col xl={3} className="d-flex flex-column justify-content-center">
              <Col xl={6}>
              <p><h5>Powered By</h5></p>     
              <img alt="log" className="img-fluid" src={logo} />
              </Col> 

            </Col>
            <Col xl={9}>
              <Row>
                {data.map((item, i) => (
                  <Col xl={3} key={i}>
                    <ul>
                      <p>{item?.heading}</p>
                      {item.content?.map((element, index) => (
                        <li key={index}>
                          {item.heading === "Community" && element.icon && (
                            <span className="me-2">{element.icon}</span>
                          )}
                          {element.name || element}
                          </li>
                      ))}
                    </ul>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer_copyright py-4">
        <div className="common_container">
          <p className="mb-0">
            Copyright ©2024, Faction Ai | All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
