import React from "react";
import blog_1 from "../../Assets/Images/blog_1.png";
import blog_2 from "../../Assets/Images/blog_2.png";
import blog_3 from "../../Assets/Images/blog_3.png";
import community from "../../Assets/Images/community.png";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Row, Col } from "react-bootstrap";

const Community = () => {
  const data = [
    {
      image: blog_1,
      heading: "Aptos Labs Partners",
      text: "Aptos Labs and NBCUniversal have inked a long-term development agreement to bring innovative Web3 fan experiences, customer loyalty programs...",
    },
    {
      image: blog_2,
      heading: "Jupiter Co-Founder Proposes",
      text: "Pseudonymous co-founder of Jupiter, known as Meow, published a draft proposal to alter the tokenomics of JUP, the native token...",
    },
    {
      image: blog_3,
      heading: "Top Smart Choices for Long-Term ",
      text: "In light of the ongoing bull run, its crucial to explore some strategic choices for those aiming to secure their financial future with crypto...",
    },
  ];

  return (
    <div className="blog_page technology_page common_padding white_content">
      {/* <div className="common_padding white_content"> */}

      <div className="common_container">
        <div className="technology">
          {" "}
          <Row>
            <Col xl={7} className="m-auto">
              <div className="tech_content">
                <div className="mb-4">
                  {/* <p className="common_p">
                  <span className="blog_heading headig_text"><h1>Join a community of millions.</h1></span>
                </p> */}
                  {/* <p className="common_p"> */}
                  <span className="blog_heading headig_text text-start"><h1><b>Join a community <br />of millions.</b></h1></span>
                  <Row className="text-start mt-5">
                    <Col xl={6}>               <p className="main_text">29.7M</p>
                      <p className="sub_text">TRANSITION PER MINUTE</p></Col>
                    <Col xl={6}>
                      <p className="main_text">340M+</p>
                      <p className="sub_text">USERS</p>
                    </Col>
                  </Row>
                  <Row className="text-start mt-3">
                    <Col xl={6}>               <p className="main_text">$0.00064</p>
                      <p className="sub_text">FEE PER TRANSACTION</p></Col>
                    <Col xl={6}>
                      <p className="main_text">1.3M</p>
                      <p className="sub_text">PROJECTS BUILD</p>
                    </Col>
                  </Row>
                  {/* </p> */}
                </div>
              </div>
            </Col>
            <Col xl={5}>
              <img alt="technology" className="img-fluid rounded" src={community} />
            </Col>
          </Row>
        </div>

      </div>
    </div>
  );
};

export default Community;