import React from "react";
import Header from "./header";
import Footer from "./footer";
import List from "./list";

const index = () => {
  return (
    <React.Fragment>
      <Header />
      <List/>
      <Footer />
    </React.Fragment>
  );
};

export default index;
