import React from 'react'
import { Container, Form, InputGroup } from "react-bootstrap";
import circle from "../../Assets/Images/circle.svg";
import { FaSearch } from "react-icons/fa";


function Search() {
  return (
    <>
      <div className="blue position-relative">
        <Container>
          <div className="circle-container">
            <img src={circle} alt="" className="position-relative" />{" "}
          </div>
          <div className="text-light text-uppercase fw-normal py-4">
            Faction Ai Thunder Hawk Explorer
          </div>
          <div className="search-container">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Search by Address / Txn Hash / Block / Token"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text
                id="basic-addon2"
                className=" search-button text-light"
              >
                <FaSearch />
              </InputGroup.Text>
            </InputGroup>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Search;
