import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import header_logo from "../../Assets/Images/header_logo.png";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [navbarColor, setNavbarColor] = useState("header_page");

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setNavbarColor("header_page scrolled");
    } else {
      setNavbarColor("header_page");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);
    return () => {
      window.removeEventListener("scroll", changeNavbarColor);
    };
  }, []);
  return (
    <Navbar collapseOnSelect expand="lg" className={navbarColor} fixed="top">
      <Container>
        <Navbar.Brand href="#home">
          <div className="d-flex align-items-center">
            <img src={header_logo} alt="header-logo" />
            <div className="ms-2">
              <span className="fw-bold">Faction Ai</span>{" "}
              <span style={{ color: "#c6086e" }}>ThunderHawk</span>
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <NavLink to="/explorer" className="nav-link">
              Explorer
            </NavLink>
            <Nav.Link href="#">Ecosystem</Nav.Link>
            <Nav.Link href="#">Bridge</Nav.Link>
            <Nav.Link href="#">Developers</Nav.Link>
            <Nav.Link href="#">About</Nav.Link>
            <Nav.Link href="#">White Papper</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
