// import { useEffect } from "react";
// import "./App.css";
// import LandingPage from "./Pages/LandingPage/index";
// import AOS from "aos";
// // import "aos/dist/aos.css";

// function App() {
//   useEffect(() => {
//     AOS.init();
//   }, []);
//   return <LandingPage />;
// }

// export default App;

import React, { useEffect } from "react";
import AOS from "aos";
import "./App.css";
import LandingPage from "./Pages/LandingPage/index";
import TransactionDetail from "./Pages/TransactionDetail/index";
import Explorer from "./Pages/Explorer/index";
// import Explorer2 from "./Pages/ExplorerNew";
import Transactions from "./Pages/Transactions/index";
import Latest from "./Pages/Latest/index";
import Smartcontractlist from "./Pages/Smartcontractlist/index";
import SmartcontractDetails from "./Pages/SmartcontractDetails/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes,Route } from "react-router-dom";

const App = () => {
  useEffect(() => { AOS.init();}, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/explorer" element={<Explorer />} />
          <Route path="/transactions/:txHash" element={<Transactions />} />
          <Route path="/latestTransactions" element={<Latest />} />
          <Route path="/transactionDetail" element={<TransactionDetail />} />
          <Route path="/smartcontractlist" element={<Smartcontractlist />} />
          <Route path="/smartcontractdetails" element={<SmartcontractDetails />} />
          {/* <Route element={<PrivateRoutes />}>
        <Route element={<Layout />}>
          {authProtectedLayoutRoutes?.map((item, i) => (
            <Route key={i} path={item.path} element={<item.component />} />
          ))}
        </Route>
      </Route> */}
        </Routes>
      </BrowserRouter>
      {/* <LandingPage /> */}
    </>
  );
};

export default App;
