import React from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import header_logo from "../../Assets/Images/header_logo.png";
import market_cap from "../../Assets/Images/market_cap.png";
import transaction from "../../Assets/Images/transaction.png";
import block from "../../Assets/Images/block.png";
import chart from "../../Assets/Images/chart.png";
import circle from "../../Assets/Images/circle.svg";
import { FaSearch } from "react-icons/fa";

const Expsearch = ({ curr_price }) => {
  return (
    <>
      <div className="blue position-relative">
        <Container>
       
          <div className="circle-container">
            <img src={circle} alt="" className="position-relative" />{" "}
          
          </div>
          <div className="text-light text-uppercase fw-normal py-4">
            Faction Ai Thunder Hawk Explorer
          </div>
          <div className="search-container">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Search by Address / Txn Hash / Block / Token"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <InputGroup.Text
                id="basic-addon2"
                className=" search-button text-light"
              >
                <FaSearch />
              </InputGroup.Text>
            </InputGroup>
          </div>
        </Container>
      </div>
      <div className="half-bg">
        <Container>
          <div className="">
            <div className="d-flex justify-content-between">
              <div className="col-4 mt-3 p-3 border border-light rounded bg-white shadow-sm">
                <div className="d-flex align-items-center">
                  <img
                    src={header_logo}
                    alt="Logo"
                    className="img-fluid me-3 rounded-5"
                    style={{ width: "50px", height: "50px" }}
                  />
                  <div>
                    <p className="mb-1 font-size fw-medium text-black-50">
                      THUNDER PRICE
                    </p>
                    <p
                      className="mb-0 fw-bold"
                      style={{ margin: "0", padding: "0" }}
                    >
                      $ 0.45838 +0.24 %
                    </p>
                  </div>
                </div>
                <hr />
                <div className="d-flex align-items-center">
                  <img
                    src={market_cap}
                    alt="Logo"
                    className="img-fluid me-3"
                    style={{ width: "50px", height: "50px" }}
                  />
                  <div>
                    <p className="mb-1 font-size fw-medium text-black-50">
                      MARKET CAP
                    </p>
                    <p
                      className="mb-0 fw-bold"
                      style={{ margin: "0", padding: "0" }}
                    >
                      $ 495,836,615.70
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-4 mx-1 mt-3 p-3 border border-light rounded bg-white shadow-sm position-relative">
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      src={transaction}
                      alt="Logo"
                      className="img-fluid me-3"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div>
                      <p className="font-size mb-1 fw-medium text-black-50">
                        TRANSACTIONS
                      </p>
                      <p
                        className="mb-0 fw-bold"
                        style={{ margin: "0", padding: "0" }}
                      >
                        40,088,311
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="font-size mb-1 text-end fw-medium text-black-50">
                        AVG GAS PRICE
                      </p>
                      <p
                        className="mb-0 fw-bold "
                        style={{ margin: "0", padding: "0" }}
                      >
                        8.505 NANOFACTION
                      </p>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      src={block}
                      alt="Logo"
                      className="img-fluid me-3"
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div>
                      <p className="font-size mb-1 fw-medium text-black-50">
                        BLOCKS
                      </p>
                      <p
                        className="mb-0 fw-bold"
                        style={{ margin: "0", padding: "0" }}
                      >
                        10,357,814
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <p className="font-size mb-1 text-end fw-medium text-black-50">
                        BLOCK TIME
                      </p>
                      <p
                        className="mb-0 fw-bold text-end"
                        style={{ margin: "0", padding: "0" }}
                      >
                        5.64 s
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4  mt-3 p-3 border border-light rounded bg-white shadow-sm position-relative">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="font-size mb-1 fw-medium text-black-50">
                      TRANSACTION HISTORY IN 14 DAYS
                    </p>
                    <img src={chart} alt="" className="img-fluid me-3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Expsearch;
