const Images = {
  icon_1: require("./sol_1.png"),
  icon_2: require("./sol_2.png"),
  icon_3: require("./sol_3.png"),
  icon_4: require("./sol_4.png"),
  icon_5: require("./sol_5.png"),
  icon_6: require("./sol_6.png"),
  icon_7: require("./sol_7.png"),
  icon_8: require("./sol_8.png"),
  icon_9: require("./sol_9.png"),
  icon_10: require("./sol_10.png"),
  icon_11: require("./sol_11.png"),
  icon_12: require("./sol_12.png"),
  icon_13: require("./sol_13.png"),
  icon_14: require("./sol_14.png"),
  icon_15: require("./sol_15.png"),
  icon_16: require("./sol_16.png"),
  icon_17: require("./sol_17.png"),
  icon_18: require("./sol_18.png"),
  icon_19: require("./sol_19.png"),
  icon_20: require("./sol_20.png"),
  icon_21: require("./sol_21.png"),
  block_1: require("./block1.png"),
  block_2: require("./block2.png"),
  block_3: require("./block3.png"),
  block_4: require("./block4.png"),
  counts_shinami: require("./shinami.png"),
  counts_supra: require("./supra.png"),
  counts_logo: require("./Mask group (2).png"),
  counts_circle: require("./circle.png"),
  counts_aftermath: require("./aftermath.png"),
  counts_hyperspace: require("./hyperspace.png"),
  counts_bluefin: require("./bluefin.png"),
};
export default Images;
