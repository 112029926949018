import React from "react";
import { Col, Row } from "react-bootstrap";
import newlogo from "../../Assets/Images/newlogo.svg";
import { RiTwitterXFill } from "react-icons/ri";
import {
  PiTelegramLogo,
  PiRedditLogo,
  PiDiscordLogoBold,
} from "react-icons/pi";
import { TbNews } from "react-icons/tb";
import { GrBlog } from "react-icons/gr";
import FooterLogo from "../../Assets/Images/FooterLogo.png"

const Footer = () => {
  const data = [
    {
      heading: "Company",
      content: [
        "About Us",
        "Brand Assets",
        "Careers",
        "Terms & Privacy",
        "Bug Bounty",
      ],
    },
    {
      heading: "Community",
      content: [
        "API Documentation",
        "Knowledge Base",
        "Network Status",
        "Newsletters",
      ],
    },
    {
      heading: "Socials",
      content: [
        { name: "Twitter", icon: <RiTwitterXFill /> },
        { name: "Discord", icon: <PiDiscordLogoBold /> },
        { name: "Telegram", icon: <PiTelegramLogo /> },
        { name: "Reddit", icon: <PiRedditLogo /> },
        { name: "News Letter", icon: <TbNews /> },
        { name: "Blog", icon: <GrBlog /> },
      ],
    },
  ];

  return (
    <>
      <div className="footer_page py-2">
        <div className="container-fluid mt-4 mb-4">
          <Row className="padding-left padding-right">
            {/* <Col lg={5} className="d-flex flex-column justify-content-center">
           
              <div className=" d-flex">
                <img
                  alt="logo"
                  className="mb-3 img-fluid"
                  src={newlogo}
              
                />
              </div>
              <p style={{ maxWidth: "400px" }}>
                Faction Ai is a Block Explorer and Analytics Platform for
                Faction, a decentralized smart contracts platform.
              </p>
            
            </Col> */}
            <Col lg={5} className="d-flex flex-column justify-content-center">
              <div className="d-flex flex-column align-items-start mb-3">
                <h3 className="">POWERED BY</h3>
                <img
                  alt="logo"
                  className="img-fluid "
                  src={FooterLogo}
                  style={{ width: "200px", height: "61px" }}
                />
              </div>
              <p
                className="font-size mt-4"
                style={{ maxWidth: "400px", color: " #53565B" }}
              >
                Faction Ai is a Block Explorer and Analytics Platform for
                Faction, a decentralized smart contracts platform.
              </p>
            </Col>
            <Col lg={7}>
              <Row>
                {data.map((item, i) => (
                  <Col lg={4} key={i} className="mb-4">
                    <ul className="list-unstyled">
                      <li className="fw-bold mb-2">{item.heading}</li>
                      {item.content.map((element, index) => (
                        <li
                          key={index}
                          className="d-flex align-items-center mb-2"
                        >
                          {(item.heading === "Community" ||
                            item.heading === "Socials") &&
                            element.icon && (
                              <span className="me-2">{element.icon}</span>
                            )}
                          {element.name || element}
                        </li>
                      ))}
                    </ul>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer_copyright py-4">
        <div className="container-fluid padding-left padding-right ">
          <p className="mb-0">
            Copyright ©2024, Faction Ai | All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
