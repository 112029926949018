import React from "react";
import "./latest.css";
import Header from "./header";
import Table from "./table";
import Footer from "./footer";
import Search from "./search";
const index = () => {
  return (
    <React.Fragment>
      <Header />

      <Search />
      <Table/>
      <Footer />    
    </React.Fragment>
  );
};

export default index;
