import React from "react";
import inn_icon from "../../Assets/Images/inn_icon.png";
import Slider from "react-slick";

const EasyToStart = () => {
  const data = [
    {
      image: inn_icon,
      heading: `<p>Hybrid <br/>Blockchain</p>`,
      content1: "Offers the flexibility of a hybrid blockchain.",
      content2:
        "Combines the security of a private blockchain with the transparency of a public blockchain.",
      content3: "Catering to diverse enterprise needs.",
    },
    {
      image: inn_icon,
      heading: `<p>Decentralized and Autonomous Decision-Making</p>`,
      content1: "AI and smart contracts for efficient decision-making.",
      content2: "Eliminates intermediaries for autonomous operations.",
      content3: "Ensures trustless agreements with smart contract execution.",
    },
    {
      image: inn_icon,
      heading: `<p>Enhanced Security and Data Integrity</p>`,
      content1: "Integration of quantum computing for advanced encryption.",
      content2: "AI-driven threat detection for ensuring data integrity.",
      content3:
        "Immutable ledger and tamper-proof transactions for enhanced security.",
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    centerPadding: "20px",
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="easy_to_start_page common_padding">
      <div className="common_container">
        <div className="content_text">
          <h3 className="headig_text">Easy to Build, Easy to Start</h3>
          <p className="common_p">
            You can get started in just a few minutes Powerful developer
            <br /> tools to make the most of the possibilities
          </p>
        </div>
        <div className="mt-5 easy_to_start_slider_elememnt">
          <Slider {...settings}>
            {data.map((item, i) => {
              return (
                <div
                  className="slider_card"
                  key={i}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <img
                    alt="card"
                    src={item?.image}
                    style={{
                      height: "50px",
                      width: "60px",
                      marginTop: "30px",
                      marginBottom: "10px",
                    }}
                  />
                  <div
                    className="card_heading"
                    dangerouslySetInnerHTML={{
                      __html: item?.heading,
                    }}
                  ></div>
                  <ul style={{ listStyleType: "disclosure-closed" }}>
                    <li>
                      <p className="common_p">{item?.content1}</p>
                    </li>
                    <li>
                      <p className="common_p">{item?.content2}</p>
                    </li>
                    <li>
                      <p className="common_p">{item?.content3}</p>
                    </li>
                  </ul>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default EasyToStart;
