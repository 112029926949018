import React from "react";
import "./transactions.css";
import Header from "./header";
import Overview from "./overview";
import Footer from "./footer";


const index = () => {
  return (
    <React.Fragment>
    
      <Header />
     <Overview />
     <Footer/>
     
    </React.Fragment>
  );
};

export default index;
