import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import innovation_img from "../../Assets/Images/innovation.png";
import inn_icon from "../../Assets/Images/inn_icon.png";

const Innovation = () => {
  return (
    <div className="innovation_page common_padding">
      <div className="common_container">
        <Row>
          <Col xl={6}>
            <div
              className="innovation_img"
              data-aos="slide-left"
              data-aos-duration="1000"
            >
              <img
                src={innovation_img}
                alt="innovation"
                className="img-fluid"
              />
            </div>
          </Col>
          <Col xl={6} className="right_side_content">
            <div
              className="innovation_text"
              data-aos="slide-right"
              data-aos-duration="1000"
            >
              <h3 className="headig_text">
                ThunderHawk the forefront of
                <br />
                <span className="highlighted_text"> blockchain innovation</span>
              </h3>
              <p className="common_p">
                Platform leverages Starfire to ensure that all decisions are
                tamper-proof, guaranteeing the integrity of data and
                transactions. With ThunderHawk, you can trust that AI algorithms
                are executed swiftly, enabling you to make informed decisions
                with confidence.{" "}
              </p>
              <p className="common_p">
                Join us at the forefront of blockchain innovation and experience
                a new era of secure and efficient blockchain technology.
              </p>
              <button className="btn common_btn">Learn More</button>
            </div>
          </Col>
        </Row>
        <Row className="innovation_bottom_content">
          <Col
            xl={4}
            className="m-auto"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <h2 className="headig_text">
              Why Build on
              <br />
              <span className="highlighted_text">ThunderHawk</span>
            </h2>
            <Row>
              <Col xl={2} style={{ alignItems: "center", display: "flex" }}>
                <p className="hash">#</p>
              </Col>
              <Col xl={10}>
                <p className="common_p">
                  Transform ThunderHawk, a blockchain platform
                  <br /> renowned for its security, transparency, and
                  <br /> ability to adapt to future innovations in the
                  <br /> blockchain space.
                </p>
              </Col>
            </Row>
          </Col>
          <Col xl={8}>
            <div
              className="inn_bottom_right d-flex gap-4 mb-4 align-items-center"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img
                src={inn_icon}
                alt="innovation icon"
                className="img-fluid inn_icon"
              />
              <p className="common_p">
                <span className="p_span">Enhanced Security:</span> ThunderHawk
                employs advanced encryption and AI technology to ensure the
                security and integrity of your data and transactions, providing
                peace of mind in an increasingly digital world.
              </p>
            </div>
            <div
              className="inn_bottom_right d-flex gap-4 mb-4 align-items-center"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img
                src={inn_icon}
                alt="innovation icon"
                className="img-fluid inn_icon"
              />
              <p className="common_p">
                <span className="p_span">Transparency and Trust:</span>{" "}
                ThunderHawk's blockchain technology, all transactions are
                recorded and stored in a transparent and immutable ledger,
                fostering trust among users and stakeholders.
              </p>
            </div>
            <div
              className="inn_bottom_right d-flex gap-4 mb-4 align-items-center"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img
                src={inn_icon}
                alt="innovation icon"
                className="img-fluid inn_icon"
              />
              <p className="common_p">
                <span className="p_span">Decentralized Decision Making:</span>{" "}
                ThunderHawk enables decentralized decision-making, empowering
                users to participate in the governance of the platform and
                ensuring that no single entity has control over the network.
              </p>
            </div>
            <div
              className="inn_bottom_right d-flex gap-4 mb-4 align-items-center"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img
                src={inn_icon}
                alt="innovation icon"
                className="img-fluid inn_icon"
              />
              <p className="common_p">
                <span className="p_span">Future Proof:</span>{" "}
                ThunderHawk is built on innovative and scalable technology,
                making it adaptable to future developments in blockchain 
                technology and ensuring that your investment is future-proof.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Innovation;
