import React from "react";
import blog_1 from "../../Assets/Images/blog_1.png";
import blog_2 from "../../Assets/Images/blog_2.png";
import blog_3 from "../../Assets/Images/blog_3.png";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Row } from "react-bootstrap";

const Blog = () => {
  const data = [
    {
      image: blog_1,
      heading: "Aptos Labs Partners",
      text: "Aptos Labs and NBCUniversal have inked a long-term development agreement to bring innovative Web3 fan experiences, customer loyalty programs...",
    },
    {
      image: blog_2,
      heading: "Jupiter Co-Founder Proposes",
      text: "Pseudonymous co-founder of Jupiter, known as Meow, published a draft proposal to alter the tokenomics of JUP, the native token...",
    },
    {
      image: blog_3,
      heading: "Top Smart Choices for Long-Term ",
      text: "In light of the ongoing bull run, its crucial to explore some strategic choices for those aiming to secure their financial future with crypto...",
    },
  ];

  return (
    <div className="blog_page common_padding">
      <div className="common_container">
        <div className="blog_heading">
          <h3 className="headig_text">From the blog</h3>
          <p className="common_p">
            You can get started in just a few minutes Powerful developer
            <br /> tools to make the most of the possibilities
          </p>
        </div>
        <div className="blog_card_content">
          <Row>
            {data?.map((item, i) => (
              <div className="blog_card col-4" key={i}>
                <img className="img-fluid" alt="blog" src={item?.image} />
                <div className="blog_sub_card">
                  <h6>{item?.heading}</h6>
                  <div className="bottom_content">
                    <p className="common_p p_blog_text">{item?.text}</p>
                    <button className="btn common_btn2">
                      More Info <IoIosArrowRoundForward />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Blog;
