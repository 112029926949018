import React from "react";
import "./explorer.css";
import Header from "./header";
import Expfooter from "./expfooter";

import NavbarSecond from "./navbar";
import Expsearch from "./expsearch";
import Expbanner from "./expbanner";
// import Banner from "./banner";
// import Counts from "./counts";
// import Innovation from "./innovation";
// import EasyToStart from "./easyToStart";
// import Technologies from "./technologies";
// import Solutions from "./solutions";
// import Blog from "./blog";
// import Community from "./community";
// import Contact from "./contact";
// import Footer from "./footer";

const index = () => {
  return (
    <React.Fragment>
      <Header />
      <Expsearch />
      <Expbanner />
      <Expfooter />
    </React.Fragment>
  );
};

export default index;