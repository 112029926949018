import React from 'react'
import left from "../../Assets/Images/left.svg";
import right from "../../Assets/Images/right.svg";
import mark from "../../Assets/Images/mark.svg";
import { Container } from "react-bootstrap";
function Details() {
  return (
    <>
      <div className="bg-color  padding-bottom border">
        <Container>
          <div className="d-flex my-5 gap-5  ">
            <div>Transaction Details</div>
          </div>

          <div className="mt-3 p-3 border rounded bg-white shadow-sm font-size text-gray">
            <div className="d-flex padding-left1 padding-right1 align-items-center  mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Transaction ID:</p>
              </div>
              <div className="col-9">
                3941c25eb82f22cd67b3983fdae1720f9cc3a2e0013f9bfcb5e27b2058b08992
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Validation Code:</p>
              </div>
              <div className="col-9">VALID</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Payload Proposal Hash:</p>
              </div>
              <div className="col-9 text-pink ">
                d80f57843da07930b34b69629f50d7853989f26fc978af675f0198f484e12f02
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Creator MSP:</p>
              </div>
              <div className="col-9 ">Org1MSP</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Endorser:</p>
              </div>
              <div className="col-9 ">
                <span>{'{"Org1MSP"}'}</span>
              </div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Chaincode Name:</p>
              </div>
              <div className="col-9 ">
                <span className="text-pink">_lifecycle</span>
              </div>
            </div>
            <hr />
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Type:</p>
              </div>
              <div className="col-9 ">10,916bytes</div>
            </div>
            <hr />
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Gas Used / Limit</p>
              </div>
              <div className="col-9 ">ENDORSER_TRANSACTION</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Time:</p>
              </div>
              <div className="col-9 ">2024-09-03T12:55:36.958Z</div>
            </div>
            <div className="d-flex padding-left1 padding-right1 align-items-center mb-3">
              <div className="d-flex align-items-center flex-grow-1 col-3">
                <img src={mark} alt="mark" className="me-2" />
                <p className="mb-0">Direct Link:</p>
              </div>
              <div className="col-9 pink">
                http://localhost:8091/?
                tab=transactions&transld=3941c25eb82f22cd67b3983fdae1720f9cc3a2e0013f9bfcb5e
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Details
