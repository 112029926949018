import React from "react";

import Header from "./header";

import Footer from "./footer";
import Details from "./details";

const index = () => {
  return (
    <React.Fragment>
      <Header />
     <Details/>
      <Footer />
    </React.Fragment>
  );
};

export default index;
