import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import tech_img from "../../Assets/Images/technology.png";
import ai_icon from "../../Assets/Images/ai.png";
import smart_icon from "../../Assets/Images/smart.png";
import blockchain_icon from "../../Assets/Images/blockchain.png";
import Images from "../../Assets/Images";

const Technologies = () => {
  const solData = [
    {
      imgage: Images.icon_1,
      text: "Supply Chain Traceability",
    },
    {
      imgage: Images.icon_2,
      text: "Digital Identity Management",
    },
    {
      imgage: Images.icon_3,
      text: "Smart Contracts",
    },
    {
      imgage: Images.icon_4,
      text: "Tokenization",
    },
    {
      imgage: Images.icon_5,
      text: "Digital Voting",
    },
    {
      imgage: Images.icon_6,
      text: "Supply Chain Optimization",
    },
    {
      imgage: Images.icon_7,
      text: "Immutable Record Keeping",
    },
    {
      imgage: Images.icon_8,
      text: "Real-time Asset Tracking",
    },
    {
      imgage: Images.icon_9,
      text: "Automated Compliance",
    },
    {
      imgage: Images.icon_10,
      text: "Decentralized Marketplace",
    },
    {
      imgage: Images.icon_11,
      text: "Energy Trading",
    },
    {
      imgage: Images.icon_12,
      text: "Asset Management",
    },
    {
      imgage: Images.icon_13,
      text: "Digital Notary Services",
    },
    {
      imgage: Images.icon_14,
      text: "Healthcare Data Management",
    },
    {
      imgage: Images.icon_15,
      text: "Loyalty Programs",
    },
    {
      imgage: Images.icon_16,
      text: "Cross-border Payments",
    },
    {
      imgage: Images.icon_17,
      text: "Supply Chain Financing",
    },
    {
      imgage: Images.icon_18,
      text: "Fraud Detection and Prevention",
    },
    {
      imgage: Images.icon_19,
      text: "Intellectual Property Protection",
    },
    {
      imgage: Images.icon_20,
      text: "Decentralized Autonomous (DAOs)",
    },
    {
      imgage: Images.icon_21,
      text: "Supply Chain Traceability",
    },
  ];
  const rows = [];

  for (let i = 0; i < solData.length; i += 7) {
    rows.push(solData.slice(i, i + 7));
  }
  return (
    <div className="technology_page common_padding mt-4 mb-4">
      <div className="common_container">
        <div className="technology">
          {" "}
          <Row>
          <Col xl={5}>
              <img alt="technology" className="img-fluid" src={tech_img} />
            </Col>
            <Col xl={6}>
              <div className="tech_content">
                <div className="mb-5">
                  <h3 className="headig_text">
                    <span className="highlighted_text"> Technologies</span> We
                    Employ
                  </h3>
                  <p className="common_p">
                    <span className="p_span">Web 4.0, Aggregated: </span>
                    Starfire-Powered AI Blockchain for Infinite Scalability
                  </p>
                </div>
                <div className="list_content mt-3">
                  <div
                    className="d-flex gap-4 mb-5 align-items-center"
                    data-aos="slide-up"
                    data-aos-duration="1000"
                  >
                    <img
                      src={ai_icon}
                      alt="technology icon"
                      className="img-fluid tech_icon"
                    />
                    <p className="common_p">
                      <span className="p_span">AI Integration:</span> How AI
                      algorithms are integrated with the blockchain.
                    </p>
                  </div>
                  <div
                    className="d-flex gap-4 mb-5 align-items-center"
                    data-aos="slide-up"
                    data-aos-duration="1000"
                  >
                    <img
                      src={blockchain_icon}
                      alt="technology icon"
                      className="img-fluid tech_icon"
                    />
                    <p className="common_p">
                      <span className="p_span">Blockchain Architecture:</span>{" "}
                      Technical details of <span className="highlighted_text"><b>ThunderHawk's</b> </span> blockchain
                      infrastructure.
                    </p>
                  </div>
                  <div
                    className="d-flex gap-4 mb-4 align-items-center"
                    data-aos="slide-up"
                    data-aos-duration="1000"
                  >
                    <img
                      src={smart_icon}
                      alt="technology icon"
                      className="img-fluid tech_icon"
                    />
                    <p className="common_p">
                      <span className="p_span">Smart Contracts: </span>{" "}
                      Explanation of smart contracts and how they work on the
                      platform.
                    </p>
                  </div>
                </div>
              </div>
            </Col>

          </Row>
        </div>
        {/* <div className="solution text-center">
          <div className="solution_content common_padding">
            <h3 className="headig_text">
              Solutions -<span className="highlighted_text"> ThunderHawk </span>
              could provide
            </h3>
            <p className="common_p">
              You can get started in just a few minutes Powerful developer{" "}
              <br /> tools to make the most of the possibilities
            </p>
          </div>
          <div className="sol_icons_content">
            <div className="row gap-4">
              {rows.map((row, rowIndex) => (
                <div className="row gap-4" key={rowIndex}>
                  {row.map((item, colIndex) => (
                    <div
                      className="col sol_icon_div"
                      key={colIndex}
                      data-aos="flip-down"
                      data-aos-duration="1000"
                    >
                      <img alt="icon" src={item?.imgage} />
                      <p className="">{item.text}</p>
                    </div>
                  ))}{" "}
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Technologies;
