import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { SlSocialFacebook } from "react-icons/sl";
import { RiLinkedinBoxLine, RiTwitterXFill } from "react-icons/ri";
import { PiTelegramLogo } from "react-icons/pi";
import { IoLogoInstagram } from "react-icons/io5";

const Contact = () => {
  return (
    <>
      <div className="contact_page common_padding">
        <div className="common_container">
          <Row>
            <Col xl={6} className="d-flex flex-column justify-content-center">
              <h2 className="headig_text">
                Contact
                <br />
                <span className="highlighted_text"> Information</span>
              </h2>
              <p className="common_p">
                We’re happy to answer any questions you may have and help your
                determine which of our services best fit your needs.
              </p>
              <h6>
                <span className="p_span">Address: </span>
              </h6>
              <p className="common_p">
                House 168/170, Avenue 01, Mirpur DOHS, Dhaka Bangladesh
              </p>
              <div className="contact_social_icons d-flex">
                <h6>
                  {" "}
                  <span className="p_span">Social</span>
                </h6>
                <div className="social_icons ms-5 d-flex gap-4">
                  <SlSocialFacebook size={25} />
                  <RiTwitterXFill size={25} />
                  <PiTelegramLogo size={25} />
                  <IoLogoInstagram size={25} />
                  <RiLinkedinBoxLine size={25} />
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <div className="free_consulting">
                <h3 className="headig_text mb-5">Make a free consulting</h3>
                <Form>
                  <Row className="gy-4">
                    <Col xl={6}>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Your Name"
                      />
                    </Col>
                    <Col xl={6}>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Email Address"
                      />
                    </Col>
                    <Col xl={6}>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Your Name"
                      />
                    </Col>
                    <Col xl={6}>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Email Address"
                      />
                    </Col>
                    <Col xl={12}>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Company/Organization"
                      />
                    </Col>
                  </Row>
                  <div className="check_box d-flex align-items-center my-4">
                    <input type="checkbox" />
                    <h6 className="ms-2">
                      {" "}
                      I agree to Accubits's Privacy and Cookies Policy.
                    </h6>
                  </div>
                  <div className="text-start">
                    {" "}
                    <button className="btn common_btn">Submit</button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Contact;
